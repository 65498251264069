@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  padding-bottom: 48px;
  --title-mb: 20px;
  --paragraph-mb: 25px;

  &__title {
    margin-bottom: 24px !important;
  }

  &__descr {
    margin-bottom: 32px !important;
  }
}

.notFound {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 25px;
  align-content: center;
  flex-grow: 1;
  text-align: center;
}

.notFound_Image {
  display: flex;
  justify-content: center;
  height: 50px;

  img {
    width: auto;
    height: 100%;
  }
}

.list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 20px;

  & li {
    &:nth-of-type(3n+1) {
      & .subscription {
        background: var(--bg-subscription-card3n1);
      }
    }

    &:nth-of-type(3n+2) {
      & .subscription {
        background: var(--bg-subscription-card3n2);
      }
    }

    &:nth-of-type(3n+3) {
      & .subscription {
        background: var(--bg-subscription-card3n3);
      }
    }
  }
}

.subscription {
  --card-padding: 18px 14px;
  border-radius: 14px;
  background-color: var(--bg-subscription-single-card);
}

.subscription_Header {
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 8px;

  @include maxWidth($bp-mobile) {
    flex-direction: column-reverse;
  }


  &_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 16px;
  }

  p {
    @include h4;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $border-3;
    opacity: 0.2;
  }
}

.subscription_Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.subscription_Title {
  @include h4;
}

.subscription_Subtitle {
  @include paragraph2;
  font-weight: 700;
}

.subscription_Interval {
  display: inline-block;
  padding: 2px 9px 3px;
  border-radius: 30px;
  background: $gray;
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 13px;
  line-height: 136%;
  color: $blue;
  text-align: center;
}

.subscription_Desc {
  @include paragraph2;
  margin: 4px 0 16px;
}

.subscription_Button {
  width: 100%;
  padding: 10px;
  min-height: 0;
  font-family: $font-main;
  font-weight: 800;
  font-size: 13px;
  line-height: 144%;
  text-align: center;
  color: $blue;
}