@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.banner {
  position: relative;
  width: 100%;
  background-color: #C3D7FB;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 23px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right;

  @include maxWidth($bp-tablet) {
    background-size: auto 65%;
  }

  @include maxWidth($bp-mTablet) {
    background-size: auto 60%;
  }

  @include maxWidth($bp-lMobile) {
    padding: 23px 23px 64px;
    background-size: auto 50%;
  }

  @include maxWidth($bp-mobile) {
    background-size: auto 45%;
  }

  &__title {
    font-size: 24px !important;
    line-height: 28px !important;
    text-align: center !important;

    @include maxWidth($bp-lMobile) {
      font-size: 16px !important;
      line-height: 23px !important;
    }
  }

  &__link {
    font-family: $font-secondary;
    background: $gray;
    border-radius: 28px;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 17.3px;
    color: $blue;
    transition: background .3s;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      transform: rotate(270deg);
      width: 14px;
      height: 14px;
    }

    &:hover {
      background: #CBD0DCFF;
    }
  }
}

.advertisingButton {
  font-family: $font-secondary;
  font-size: 12px;
  line-height: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 4px 8px;
  color: white;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 10;
  cursor: pointer;
  transition: opacity .3s;

  @include maxWidth($bp-mobile) {
    font-size: 8px;
    line-height: 11px;
    padding: 2px 5px;
  }

  &:hover {
    opacity: 0.7;
  }
}