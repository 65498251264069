@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.errorWrapper {
  position: relative;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px 12px 0;
  text-align: center;
  color: var(--clr-error);
  transform-origin: top left;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 11px;
  line-height: 144%;

  &.on-enter {
    opacity: 0;
    transform: translateY(-2px);
  }

  &.on-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity 160ms, transform 160ms;
  }

  &.on-exit {
    opacity: 1;
    transform: none;
  }

  &.on-exit-active {
    opacity: 0;
    transform: translateY(-2px);
    transition: opacity 160ms, transform 160ms;
  }
}