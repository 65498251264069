@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.input {
  position: relative;
  width: 100%;
  height: 46px;
  padding: 0 15px;
  margin-bottom: 6px;
  font-family: $font-main;
  font-weight: 400;
  font-size: 36px;
  line-height: 144%;
  text-align: center;
  caret-color: currentColor;
  color: $gray-6;
  background: $white;
  border-radius: 12px;
  font-size: 15px;
  text-align: left;
  border: 1px solid $gray;
  transition: box-shadow 0.15s linear, border-color 0.15s linear, background-color 0.15s linear;
  z-index: 1;

  &_isDiscountPage {
    font-size: 16px !important;
  }
  &_disabled {
    background-color: #f5f8fd !important;
  }

  &__wraper {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 10;
  }

  &_withIcon {
    padding-left: 50px;
  }

  &:focus {
    border-color: $blue;
    box-shadow: 0px 5px 20px 0px rgba(57, 109, 206, 0.15);
    background-color: $gray;
    z-index: 2;
  }

  &::placeholder {
    color: #7c808b;
  }
}

.danger {
  border: 1px solid $red;
  box-shadow: unset;
}
