@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  padding: 0 0 32px;
  display: flex;
  flex-grow: 1;

  --title-mb: 8px;
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  gap: 32px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--clr-bg-border);
}

.content {
  margin: 16px 0;
  padding: 16px 0;
  border-bottom: 1px solid var(--clr-bg-border);

  &__paragraph {
    color: $gray-4;
  }

  &__title {
    color: $gray-3;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  --button-width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}

.price {
  text-align: center;
  margin-bottom: 8px;
  color: $gray-2;
}

.time {
  color: $gray-4;
  text-align: center;
}

.rates {
  font-size: 14px;
  font-weight: 500;
}

.rate {
  display: grid;
  grid-template-columns: minmax(0, 1.2fr) repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #999DA9;

  &>*:nth-child(2) {
    text-align: center;
  }

  &>*:last-child {
    color: var(--clr-text-main);
    text-align: right;
  }
}

.rates_Title {
  display: block;
  margin-bottom: 16px;
}

.rates_Footer {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-gap: 10px;
  color: var(--clr-text-main);
}

.requisites {
  margin-top: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid $border-2;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 11px;
  line-height: 144%;
  color: $gray-8;

}

.car-number {
  width: fit-content;
  margin: 8px auto 0;
  color: $blue;
  padding: 7px 16px;
  background: $gray;
  border-radius: 8px;
  text-align: center;
}

.finesWrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 8px 0;

  p {
    font-weight: 600;
  }


  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}