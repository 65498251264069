@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrap {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overscroll-behavior: none;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}