@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;

  --title-mb: 32px;

  &__footer {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 100%;

  &--discount-page {
    gap: 0;
    justify-content: flex-start;

    @include maxWidth($bp-tablet) {
      width: calc(100% - var(--container-space, #{$space-tablet}) * 3);
    }

    @include maxWidth($bp-mTablet) {
      width: calc(100% - var(--container-space, #{$space-mTablet}) * 3);
    }

    @include maxWidth($bp-lMobile) {
      width: calc(100% - var(--container-space, #{$space-lMobile}) * 3);
    }
  }
}

.input {
  display: block;
  width: 100%;
  margin: 0 auto 24px;
  --input-text-align: center;

  & input {
    text-transform: uppercase;
  }

  &--discount-page {
    margin: 0 auto;
  }

  &__error {
    position: static;
    width: 80%;
    margin: 0 auto;
    &_isDiscountPage {
      @include minWidth($bp-lMobile) {
        font-size: 15px !important;
      }
    }
  }

  &--email {
    margin-top: 16px;
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid $border-2;

    & input {
      text-transform: none;
    }
  }
}

.button {
  --button-width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}

.message {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  font-weight: var(--title-fw, bold);
  font-size: var(--title-fz, 28px);
  line-height: var(--title-lh, 1.25);

  &__title {
    margin-bottom: 8px;
    &_isDiscountPage {
      @include minWidth($bp-lMobile) {
        font-size: 18px !important;
      }
    }
  }

  &__text {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 13px;
    line-height: 136%;
    color: $gray-3;
    &_isDiscountPage {
      @include minWidth($bp-lMobile) {
        font-size: 14px !important;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__message {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    a {
      color: var(--clr-bg-primary);
      font-weight: 700;
    }
    &_isDesktop {
      @include maxWidth($bp-tablet) {
        display: none;
      }
      @include maxWidth($bp-lMobile) {
        display: inline-block;
      }
    }
    &_isTablet {
      display: none;
      @include maxWidth($bp-tablet) {
        display: inline-block;
      }
      @include maxWidth($bp-lMobile) {
        display: none;
      }
    }
  }
}

.title {
  font-size: 24px !important;
  &_isDiscountPage {
    font-size: 30px !important;
    padding-top: 16px;
  }
}


.subscription {
  margin: 32px 0;
}

.subscription__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  p {
    color: #ef4a59;
    font-size: 16px;
    line-height: 22px;
  }

  a {
    font-size: 18px;
    line-height: 24px;
    color: #24b1ec;
  }
}

.inputNumber {
  @include minWidth($bp-lMobile) {
    font-size: 18px !important;
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.4);
  }
}

.emailInput {
  &__wrapper {
    margin-top: 0;
  }

  font-size: 16px !important;
}
