@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

$transition-duration: 0.2s;
$transition-opacity: 70%;
$link-transition: opacity $transition-duration;

.container {
  background-color: #eff4ff;
  height: 100%;
}

.page {
  max-width: 1440px;
  margin: 0 auto;
}

// Header

.header {
  min-height: 395px;
  padding: 30px 40px 90px;
  background-color: $blue;
  background-image: url(/images/hippo-success.svg);
  background-repeat: no-repeat;
  background-position: 80% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 12px 12px;

  @include maxWidth($bp-tablet) {
    background-size: 60% 60%;
    background-position: 115% 100%;
  }

  @include maxWidth($bp-mTablet) {
    padding: 25px 25px 100px;
  }

  @include maxWidth($bp-mobile) {
    padding: 12px 16px 65px;
    min-height: 266px;
  }
}

.header_pending {
  background-image: url(/images/hippo-is-pending.svg);
}

.header__top {
  display: flex;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  align-items: center;
  column-gap: 10px;

  @include maxWidth($bp-mobile) {
    column-gap: 4px;
  }
}

.logoImage {
  width: 95px;

  @include maxWidth($bp-mobile) {
    width: 50px;
  }
}

.logoTitle {
  color: $white;

  @include maxWidth($bp-mobile) {
    font-size: 18px !important;
  }
}

.header__phone {
  margin-top: 5px;
  width: 64px;
  height: 64px;
  transition: $link-transition;

  @include maxWidth($bp-mobile) {
    width: 32px;
    height: 32px;
  }
}

.header__phone:hover {
  cursor: pointer;
  opacity: $transition-opacity;
}

.header__title {
  color: $white;
  font-size: 48px !important;
  line-height: 57.6px !important;
  font-family: $font-secondary !important;

  span {
    display: block;
  }

  @include maxWidth($bp-tablet) {
    max-width: 600px;
  }

  @include maxWidth($bp-mTablet) {
    font-size: 38px !important;
    line-height: 47px !important;
    max-width: 400px;
  }

  @include maxWidth($bp-mobile) {
    font-size: 26px !important;
    line-height: 37.44px !important;
    max-width: 280px;
  }
}

// Main

.main {
  font-family: $font-secondary;
  padding: 80px;

  @include maxWidth($bp-mTablet) {
    padding: 32px;
  }

  @include maxWidth($bp-mobile) {
    padding: 32px 16px;
  }
}

.main__content {
  background-color: $white;
  border-radius: 12px;
  max-width: 686px;
  margin: 0 auto;
  padding: 24px;

  @include maxWidth($bp-mobile) {
    padding: 24px 16px;
  }
}

.description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 23.04px;
}

.titleWithTimer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.timer {
  font-weight: 700;
  font-size: 20px;
  line-height: 28.8px;
  color: $blue;
}

.subtitle {
  font-family: $font-secondary;
  font-size: 26px !important;
  line-height: 37.44px !important;
}

.button {
  margin-top: 24px;
  width: 280px;
  font-size: 20px;
  line-height: 28.8px;
  font-weight: 800;
  display: inline-block;

  span {
    font-family: $font-main;
  }

  @include maxWidth($bp-mobile) {
    width: 100%;
    font-size: 15px;
    line-height: 21.6px;
    min-height: 50px;
    height: 50px;
  }
}

.infoListItem {
  border-bottom: 1px solid $gray-10;
  padding: 25px 0 8px;
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
  align-items: center;

  @include maxWidth($bp-tablet) {
    grid-template-columns: auto;
    gap: 8px;
    padding: 16px 0 8px;
  }
}

.infoListItem:last-child {
  border: none;
}

.infoListItemTitle {
  font-family: $font-secondary !important;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 24.48px !important;
  color: var(--color-gray3);

  @include maxWidth($bp-mobile) {
    font-size: 16px !important;
    line-height: 21.76px !important;
  }
}

.infoListItemTextWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.infoListItemText {
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;

  @include maxWidth($bp-mobile) {
    font-size: 16px !important;
    line-height: 23.04px !important;
  }
}

.link {
  color: $blue;
  transition: $link-transition;
  display: inline-block;
}

.link:hover {
  opacity: $transition-opacity;
}

// Error page

.headerEmpty {
  padding: 40px;

  @include maxWidth($bp-mobile) {
    padding: 16px;
  }
}

.blueLogoTitle {
  color: $blue;
}

.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.errorImage {
  width: 397px;

  @include maxWidth($bp-mobile) {
    width: 337px;
    margin-top: 40px;
  }
}

.errorTitle {
  font-family: $font-secondary;
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 600;
  margin-top: 24px;
}

.errorMessage {
  margin-top: 12px;
  color: $gray-9;
  font-size: 18px;
  line-height: 25.2px;
  text-align: center;
  font-family: $font-secondary;

  @include maxWidth($bp-mobile) {
    font-size: 16px;
    line-height: 22.4px;
  }
}

// Loading page

.loadingPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: $blue;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

// Payments Steps

.stepsList {
  display: flex;
  padding-bottom: 15px;
  gap: 5px;
}

.stepsListItem {
  display: flex;
  align-items: center;
  color: $gray-10;
  flex-grow: 1;
  gap: 5px;

  &:last-child {
    flex-grow: 0;
  }
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid $gray-10;
  border-radius: 50%;
  font-weight: 600;
  font-size: 24px;
  line-height: 27.84px;
  font-family: $font-main;
  color: currentColor;

  @include maxWidth($bp-mobile) {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 23.2px;
  }
}

.stepNumber_active {
  color: $white;
  background-color: $blue;
  border-color: $blue;
}

.stepLine {
  padding: 0 10px;
  height: 1px;
  background-color: $gray-10;
  width: calc(100% - 50px);

  @include maxWidth($bp-mobile) {
    width: calc(100% - 40px);
  }
}

.stepLine_active {
  background-color: $blue;
}

.stepsListItem {
  &:last-child .stepLine {
    display: none;
  }
}