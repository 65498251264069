@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.card {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: var(--card-min-height, 164px);
  padding: var(--card-padding, 16px 14px);
  border-radius: var(--radius-m);
  color: var(--card-color, var(--clr-white));
  background-color: var(--card-bg-color, var(--clr-bg-primary));
  background-image: var(--card-bg-image, none);
  background-repeat: var(--card-bg-repeat, no-repeat);
  background-size: var(--card-bg-size, auto 100%);
  background-position: var(--card-bg-position, top right);

  &__Accent {
    background-color: var(--card-bg-color, var(--clr-bg-accent));
  }

  &__Decor {
    color: var(--card-color, var(--clr-text-normal));
    background-color: var(--card-bg-color, var(--clr-bg-decor));
  }
}

.link {
  transition: opacity 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.payment {
  background: var(--bg-payment-card);

  & .image {
    position: absolute;
    top: var(--icon-position-top-payment-card);
    right: var(--icon-position-right-payment-card);
    width: var(--icon-width-payment-card);
  }
}

.paymentHPApp {
  background: #2664A1;

  & .image {
    position: absolute;
    top: -30px;
    right: -80px;
  }
}

.paymentApp {
  background: #2664A1;

  & .image {
    position: absolute;
    top: -25px;
    right: 0;
    width: 170px;
  }
}

.subscription {
  background-color: var(--bg-subscription-card);

  & .image {
    position: absolute;
    bottom: var(--icon-position-bottom-subscription-card);
    right: var(--icon-position-right-subscription-card);
  }
}

.event {
  background-color: var(--bg-event-card);

  & .image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  flex-grow: 1;
  max-width: var(--card-content-max-width, 154px);
}

.longContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  flex-grow: 1;
  max-width: var(--card-content-max-width, 180px);
}

.title {
  margin-bottom: 8px;
  font-family: $font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 116%;
}

.subtitle {
  margin-bottom: 8px;
  font-family: $font-main;
  font-weight: 700;
  font-size: 18px;
  line-height: 116%;
}

.descr {
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 11px;
  line-height: 144%;
  color: $gray;
}

.button {
  position: relative;
  min-height: unset !important;
  padding: 6px 29px 6px 12px !important;
  font-family: $font-secondary !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 144% !important;
  color: $blue !important;
  background: $gray !important;
  border-radius: 28px !important;

  &::before {
    content: "";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNiA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS4yNSA4TDQuNzUgNC41TDEuMjUgMSIgc3Ryb2tlPSIjMzg3OUYwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.mobileApps {
  display: flex;
  gap: 10px;

  a:hover {
    cursor: pointer;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}
