@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.sliderDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(153, 162, 173, 0.3);
  transition: background .3s;
}

.container {
  :global {
    .slick-slide {
      border-radius: 10px;
      padding: 0 10px;

      &.slick-current {
        padding: 0;
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 16px 0;

      @include maxWidth($bp-lMobile) {
        padding: 0 0 5px;
      }

      li {
        &.slick-active {
          button {
            background: #99A2AD;
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      bottom: 16px;
      width: 24px;
      height: 24px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 9;

      @include maxWidth($bp-lMobile) {
        display: none !important;
      }

      &.slick-disabled {
        svg {
          path {
            stroke: rgba(56, 121, 240, 0.4)
          }
        }
      }

      svg {
        path {
          stroke: #3879F0;
          transition: all .3s;
        }
      }

      &.slick-next {
        right: 8px;

        svg {
          transform: rotate(270deg);
        }
      }

      &.slick-prev {
        right: 48px;

        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.slider {
  &.isOne {
    :global {
      .slick-slide {
        border-radius: 10px;

        &.slick-current {
          padding-right: 0;
        }

        &:last-child {
          &.slick-current {
            padding: 0;
          }
        }
      }
    }
  }
}

.partnerPromotion {
  border-radius: 10px;
  background-image: linear-gradient(#1E2841, #2360BC);
  position: relative;
  height: 164px;
  overflow: hidden;

  @include maxWidth($bp-lMobile) {
    height: 109px;
  }

  &__skeleton {
    height: 164px;
    width: 100%;
    border-radius: 10px;
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 25px 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 100% 95%;

    @include maxWidth($bp-tablet) {
      background-size: auto 75%;
    }

    @include maxWidth($bp-mTablet) {
      padding: 16px 16px 25px;
      background-size: auto 65%;
    }

    @include maxWidth($bp-mobile) {
      background-size: auto 60%;
      background-position: 95% 95%;
    }

    &_name {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: $white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include maxWidth($bp-lMobile) {
        font-size: 16px;
        line-height: 23px;
      }

      @include maxWidth($bp-sMobile) {
        font-size: 15px;
      }
    }

    &_link {
      font-family: $font-secondary;
      background: $gray;
      border-radius: 28px;
      padding: 6px 6px 6px 12px;
      font-size: 12px;
      font-weight: 600;
      color: $blue;
      transition: background .3s;
      width: max-content;
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        transform: rotate(270deg);
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: #CBD0DCFF;
      }
    }
  }
}

.advertisingButton {
  font-family: $font-secondary;
  font-size: 12px;
  line-height: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 4px 8px;
  color: white;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: opacity .3s;

  @include maxWidth($bp-mobile) {
    font-size: 8px;
    line-height: 11px;
    padding: 2px 5px;
    top: 5px;
    right: 5px;
  }

  &:hover {
    opacity: 0.7;
  }
}
