.container {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
  transition: transform 0.15s ease-in, opacity 0.15s ease-in;
  z-index: 100;

  &--visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}
