@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.paragraph {
  white-space: pre-wrap;
  font-weight: normal;
  font-size: var(--paragraph-fz, 18px);
  line-height: var(--paragraph-lh, 1.8);
  color: var(--paragraph-color, var(--clr-text-normal));
  text-align: var(--paragraph-align, left);

  &:not(:last-child) {
    margin-bottom: var(--paragraph-mb, 8px);
  }

  &.center {
    text-align: var(--paragraph-align, center);
  }

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 600;
    color: var(--clr-text-main);
  }

  &--level {
    &-1 {
      @include paragraph1
    }

    &-2 {
      @include paragraph2
    }
  }
}