@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(220, 224, 231, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 200;
  overflow-y: auto;
}

.modal {
  width: 653px;
  background-color: $white;
  padding: 80px;
  box-shadow: 0 0 20px rgba(56, 121, 240, 0.13);
  border-radius: 14px;
  position: absolute;
  z-index: 200;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  top: 5vh;

  @include maxWidth($bp-mTablet) {
    width: 440px;
  }

  @include maxWidth($bp-mobile) {
    padding: 24px 16px;
    width: 327px;
    gap: 12px;
  }

  &__closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background-color: #ECF2FF;
    border-radius: 50%;
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    @include maxWidth($bp-mobile) {
      width: 16px;
      height: 16px;

      svg {
        width: 8px;
        height: 8px;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 23.04px;
    text-align: center;

    @include maxWidth($bp-mobile) {
      font-size: 14px;
      line-height: 20.16px;
    }
  }

  &__subtitle {
    font-family: $font-secondary;
    font-size: 15px;
    line-height: 21.6px;
    text-align: center;

    @include maxWidth($bp-mobile) {
      font-size: 12px;
      line-height: 17.28px;
    }
  }

  &__paragraph {
    font-family: $font-secondary;
    font-size: 13px;
    line-height: 17.68px;
    font-weight: 400;
    color: $gray-3;
    white-space: pre-wrap;

    @include maxWidth($bp-mobile) {
      font-size: 12px;
      line-height: 16.32px;
    }
  }

  &__link {
    align-self: start;
    font-family: $font-secondary;
    font-size: 12px;
    line-height: 16.32px;
    color: $blue;
    text-decoration: underline;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}


