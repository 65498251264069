@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrapper {
  position: fixed;
  bottom: 0;
  z-index: 5;
  margin-top: auto;
  pointer-events: none;


  width: calc(100% - var(--container-space, #{$space-desktop}) * 2);
  max-width: var(--container-max-width, #{$max-width});
  margin-left: auto;
  margin-right: auto;

  @include maxWidth($bp-tablet) {
    width: calc(100% - var(--container-space, #{$space-tablet}) * 2);
  }

  @include maxWidth($bp-mTablet) {
    width: calc(100% - var(--container-space, #{$space-mTablet}) * 2);
  }

  @include maxWidth($bp-lMobile) {
    width: calc(100% - var(--container-space, #{$space-lMobile}) * 2);
  }

  &--hided {
    display: contents;
  }

}

.inner {
  padding: 32px 0;
  background: var(--fixed-bottom-bg, linear-gradient(to bottom, rgba(255, 255, 255, 0), white 50%));

  &>* {
    pointer-events: all;
  }
}