.link {
  display: inline;
  color: var(--link-color, var(--clr-text-accent));
  font-size: var(--link-fz, 18px);
  line-height: var(--link-lh, 1.6);
  background-image: linear-gradient(
    180deg,
    var(--link-bcolor, var(--clr-n7)),
    var(--link-bcolor, var(--clr-n7))
  );
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% 1px;

  &:hover:not(.active) {
    --link-bcolor: var(--clr-text-secondary);
  }

  &.active {
    cursor: default;
  }
}
