@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;

  --title-mb: 20px;
  --paragraph-mb: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 0 32px;
  gap: 32px;
}

.input {
  display: block;
  margin-bottom: 24px;
}

.label {
  display: block;
  margin-bottom: 12px;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: var(--clr-text-secondary);
  text-transform: uppercase;
}

.button {
  --button-width: 100%;
}