.section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.plug {
  background: #000;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 535px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_text {
    bottom: 0;
    right: 0;
    position: absolute;
    padding-right: 20px;
    padding-bottom: 20px;

    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: right;
    color: #ffffff;
  }
}

.image {
  margin-top: auto;
  display: flex;
  justify-content: center;
}
