@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;

  --title-mb: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
}

.phone {
  position: absolute;
  top: -52px;
  right: 0;
}

.button {
  --button-width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}

.radioButtons {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 180px;
  overflow: auto;
  width: 100%;
  &__item {
    max-width: 500px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}