@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;
  padding: 14px 0 32px;

  --title-mb: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}

.card {
  --card-padding: 20px 30px 30px;
  margin-bottom: 32px;
}

.card_Image {
  position: relative;
  max-height: 300px;

  &::after {
    content: '';
    display: block;
    padding-top: calc(100% * 146 / 269);
  }

  img {
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.phone {
  position: absolute;
  top: -52px;
  right: 0;
}

.info {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px 20px;
  padding-top: 25px;

  @media (max-width: 650px) {
    grid-template-columns: 2fr 1fr;
  }
}

.info_Item {
  font-weight: 500;

  dt {
    font-size: 10px;
    color: var(--clr-text-secondary);
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
  }

  dd {
    font-size: 18px;
    color: var(--clr-text-main);
  }

  &__Full {
    grid-column: 1/-1;
  }

  &__Bold {
    dd {
      font-size: 23px;
      font-weight: bold;

      @media (max-width: 650px) {
        font-size: 16px;
      }
    }
  }
}

.info_Tip {
  display: block;
  padding-top: 8px;
  font-size: 10px;
  font-weight: 500;
  color: var(--clr-text-secondary);
}