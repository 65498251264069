@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;

  --title-mb: 20px;
  --button-width: 100%;

  &__text {
    margin-bottom: 12px !important;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 136%;
    color: $gray-3;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
}

.scanner {
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  --scanner-max-width: 200px;
}

.scanInfo {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--clr-primary);

  &__Error {
    color: var(--clr-error);
  }
}

.footerMessage {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  a {
    color: var(--clr-bg-primary);
    font-weight: 700;
  }
  &_isDesktop {
    @include maxWidth($bp-tablet) {
      display: none;
    }
    @include maxWidth($bp-lMobile) {
      display: inline-block;
    }
  }
  &_isTablet {
    display: none;
    @include maxWidth($bp-tablet) {
      display: inline-block;
    }
    @include maxWidth($bp-lMobile) {
      display: none;
    }
  }
}

.footerMessageWrapper {
  margin-top: 15px;
}