@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;
  background-color: $white;
  transition: background-color 0.2s;

  --title-mb: 16px;
  --title-color: var(--clr-white);
  --paragraph-color: var(--clr-white);
  --paragraph-fz: 16px;
  --fixed-bottom-bg: 0 0;

  &__Error {
    --result-bg-color: $white;
    --result-sign-color: $white;
  }

  &__descr {
    margin: 0 !important;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 13px;
    line-height: 136%;
    text-align: center;
    color: $gray-4;
    &_isDiscountPage {
      @include minWidth($bp-lMobile) {
        color: var(--color-gray4);
        font-size: 24px;
        line-height: 140%;
      }
    }
  }

  &__text {
    margin: 0 !important;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 13px;
    line-height: 136%;
    text-align: center;
    color: $gray-3;
    &_isDiscountPage {
      @include minWidth($bp-lMobile) {
        padding-top: 4px;
        color: var(--dark-gray-1);
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }

  &__text_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & div {
      margin: 0 !important;
    }
  }

  &__text_secondary {
    font-family: $font-main;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: var(--navy);
    @include minWidth($bp-lMobile) {
      font-size: 32px !important;
    }

    &--dark {
      color: $gray-4;
    }

    &--isDiscountPage {
      font-size: 24px !important;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 64px;
    overflow-y: auto;
    @include maxWidth($bp-mTablet) {
      padding: 20px;
    }
    @include maxWidth($bp-lMobile) {
      gap: 20px;
    }
  }
  &_isErrorPage {
    justify-content: center;
    height: calc(100% - 180px);
  }
  &_isPendingPage {
    justify-content: flex-start;
  }
}

.sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
  border-radius: 50%;
  background-color: var(--clr-white);
  color: var(--result-sign-color, var(--clr-bg-success));

  &__Success {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 32px;
      height: 19px;
      border-left: 4px solid currentColor;
      border-bottom: 4px solid currentColor;
      transform: translateY(-6px) rotate(-45deg);
    }
  }

  &__Error {
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 32px;
      height: 4px;
      background-color: currentColor;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &__title {
      margin-bottom: 12px;
      color: $gray-2;
    }

    &__descr {
      color: $gray-4;
      text-align: center;
    }

    &__icon {
      margin: 0 auto 32px;
    }
  }

  &__Pending {
    background-color: transparent;
    color: var(--clr-white);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.button {
  --button-width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
  min-height: 0;
}

.buttons {
  --button-width: 100%;
  display: grid;
  grid-gap: 16px;
  &_isDiscountPage {
    margin-bottom: 30px;
  }
}

.list {
  padding-left: 28px;

  li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  & > li::before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + 14px);
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: currentColor;
  }
}

.subscriptionCard {
  margin-top: 40px;
}

.subscriptionInfo {
  --paragraph-mb: 24px;
  --app-links-mt: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.subtitle {
  line-height: 35px;
  margin-top: 16px;
  color: var(--color-gray4);
  font-size: 25px;
  @include maxWidth($bp-lMobile) {
    font-size: 20px;
    line-height: 25px;
  }
}

.extraPay {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  @include maxWidth($bp-lMobile) {
    gap: 30px;
  }
}

.qrcodeBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-weight: $font-main;
  text-align: center;
  max-width: 640px;
  color: $gray-4;

  @include maxWidth($bp-lMobile) {
    max-width: 90%;
  }
}

.footerMessage {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  a {
    color: var(--clr-bg-primary);
    font-weight: 700;
  }
  &_isDesktop {
    @include maxWidth($bp-tablet) {
      display: none;
    }
    @include maxWidth($bp-lMobile) {
      display: inline-block;
    }
  }
  &_isTablet {
    display: none;
    @include maxWidth($bp-tablet) {
      display: inline-block;
    }
    @include maxWidth($bp-lMobile) {
      display: none;
    }
  }
}

.successResultContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 34px;
  height: calc(100% - 130px);
}

.footerMessageWrapper {
  margin-top: 15px;
}

.icon {
  margin: 45px auto 30px;

  @include maxWidth($bp-lMobile) {
    margin: 25px auto 15px;
  }
}