:root {
    // Фон карточки акций партнеров в AreaPage
    --bg-partner-card: linear-gradient(180deg, #3879F0 0%, #428EFF 100%);

    // Карточка оплаты (PaymentCard)
    --bg-payment-card: linear-gradient(180deg, #1E4DA2 0%, #2360BC 100%);
    --icon-position-top-payment-card: 0;
    --icon-position-right-payment-card: 0;
    --icon-width-payment-card: auto;

    // Карточка абонемента (SubscriptionCard)
    --bg-subscription-card: var(--card-bg-color, var(--clr-bg-primary));
    --icon-position-bottom-subscription-card: 0;
    --icon-position-right-subscription-card: 0;

    // Фон карточки мероприятия (EventCard)
    --bg-event-card: #0D3D85;

    // Фон карточек абонементов
    --bg-subscription-card3n1: linear-gradient(180deg, #3879F0 0%, #428EFF 100%);
    --bg-subscription-card3n2: linear-gradient(180deg, #1E4DA2 0%, #2360BC 100%);
    --bg-subscription-card3n3: linear-gradient(180deg, #222B45 0%, #354163 100%);
    --bg-subscription-single-card: #192342;
  }