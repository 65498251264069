@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  display: flex;
  flex-grow: 1;
  padding: 14px 0 32px;

  --title-mb: 32px;

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.content {
  padding-bottom: 54px;
}

.phone {
  position: absolute;
  right: 0;
  top: -26px;
}

.card {
  --card-padding: 12px 37px 17px;
  border-radius: 24px;
}

.card_Image {
  position: relative;
  max-height: 300px;

  &::after {
    content: '';
    display: block;
    padding-top: calc(100% * 146 / 269);
  }

  img {
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.card_Text {
  font-family: $font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: center;
  color: $gray-7;
}

.button {
  width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}