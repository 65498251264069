@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--button-width, fit-content);
  min-height: 56px;
  padding-top: var(--button-pt, 15px);
  padding-bottom: var(--button-pb, 17px);
  padding-left: var(--button-pl, 20px);
  padding-right: var(--button-pr, 20px);
  font-size: var(--button-fz, 18px);
  line-height: var(--button-lh, 1.2);
  font-weight: var(--button-fw, 500);
  text-align: center;
  color: var(--button-color, var(--clr-white));
  border-radius: var(--button-bradius, var(--radius-m));
  background: var(--button-background, var(--clr-control-bg-primary));
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s, color 0.1s;

  &:focus {
    --button-background: var(--button-bg-active, var(--clr-control-bg-primary-active));
  }

  @include noTouchHover() {
    --button-background: var(--button-bg-active, var(--clr-control-bg-primary-active));
  }

  &:active {
    --button-background: var(--button-bg-active, var(--clr-control-bg-primary-active));
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }

  &.disabled {
    --button-color: var(--color-control-text-disable);
    --button-background: var(--color-control-bg-disable);
  }

  &.loading {
    pointer-events: none;

    .content {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
    }
  }
}

.secondary {
  color: var(--button-color, var(--clr-control-text-system));
  background: var(--button-background, var(--clr-control-bg-system));

  &:focus {
    --button-background: var(--clr-control-bg-system-active);
  }

  @include noTouchHover() {
    --button-background: var(--clr-control-bg-system-active);
  }

  &:active {
    --button-background: var(--clr-control-bg-system-active);
  }
}

.accent {
  color: var(--button-color, var(--clr-control-text-accent));
  background: var(--button-background, var(--clr-control-bg-accent));

  &:focus {
    --button-background: var(--clr-control-bg-accent-active);
  }

  @include noTouchHover() {
    --button-background: var(--clr-control-bg-accent-active);
  }

  &:active {
    --button-background: var(--clr-control-bg-accent-active);
  }
}

.content {
  transition: opacity 0.2s;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: 0 0;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;

  &>svg {
    width: auto;
    height: 80%;
  }
}


.transparent {
  background: transparent;
  border: 2px solid $border;
  color: $gray-5;
}