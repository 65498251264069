@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.container {
  height: 100%;
}

.section {
  min-height: 100%;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  flex-grow: 1;
  --title-mb: 8px;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
  height: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }
}

.gender {
  display: flex;
  align-items: center;
  gap: 60px;
}

.passport {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  width: 100%;
}

.rules {
  display: flex;
  flex-direction: column;
}

.source {
  padding-top: 18px;
  border-top: 1px solid var(--clr-bg-border);
}

.inputError {
  position: static;
  text-align: left;
  padding: 0;
}

.label {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 600;
  line-height: 144%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--clr-bg-border);
  margin-bottom: 6px;
}
