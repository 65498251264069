@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  padding-bottom: 48px;
  --title-mb: 20px;
  --paragraph-mb: 25px;

  &__title {
    margin-bottom: 24px !important;
  }

  &__descr {
    margin-bottom: 32px !important;
  }
}

.notFound {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 25px;
  align-content: center;
  flex-grow: 1;
  text-align: center;
}

.notFound_Image {
  display: flex;
  justify-content: center;
  height: 50px;

  img {
    width: auto;
    height: 100%;
  }
}

.list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 20px;

  & li {
    &:nth-of-type(3n + 1) {
      & .subscription {
        background: linear-gradient(180deg, #3879f0 0%, #428eff 100%);
      }
    }

    &:nth-of-type(3n + 2) {
      & .subscription {
        background: linear-gradient(180deg, #1e4da2 0%, #2360bc 100%);
      }
    }

    &:nth-of-type(3n + 3) {
      & .subscription {
        background: linear-gradient(180deg, #222b45 0%, #354163 100%);
      }
    }
  }
}

.subscription {
  --card-padding: 18px 14px;
  border-radius: 14px;
}

.subscription_Header {
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $border-3;
    opacity: 0.2;
  }
}

.subscription_Content {
  display: flex;
  flex-direction: column;
}

.subscription_Title {
  @include h4;
}

.subscription_Subtitle {
  @include paragraph2;
  font-weight: 700;
}

.subscription_Interval {
  display: inline-block;
  padding: 2px 9px 3px;
  border-radius: 30px;
  width: fit-content;
  background: $gray;
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 13px;
  line-height: 136%;
  color: $blue;
}

.subscription_Desc {
  @include paragraph2;
  margin: 4px 0 16px;
}

.subscription_Button {
  width: 100%;
  padding: 10px;
  min-height: 0;
  font-family: $font-main;
  font-weight: 800;
  font-size: 13px;
  line-height: 144%;
  text-align: center;
  color: $blue;
}

.eventCard {
  min-height: auto;
  padding: 18px 14px;
}

.description {
  color: $white;
  font-size: 13px;
  font-weight: 400;
  line-height: 136%;
  margin-bottom: 16px;
}
