@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.copiedIcon {
  color: $blue;
}

.failedIcon {
  color: $red;
}

.defaultIcon {
  color: $blue;
  transition: opacity 0.2s;
}

.defaultIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.defaultIcon:active {
  opacity: 1;
}
