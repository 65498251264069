@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(220, 224, 231, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.modal {
  width: 653px;
  background-color: $white;
  padding: 80px;
  box-shadow: 0px 0px 20px rgba(56, 121, 240, 0.13);
  border-radius: 14px;
  position: relative;
  z-index: 201;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include maxWidth($bp-mTablet) {
    width: 440px;
  }

  @include maxWidth($bp-mobile) {
    padding: 24px 16px;
    width: 327px;
    gap: 22px;
  }
}

.paragraph {
  font-family: $font-secondary;
  font-size: 20px;
  line-height: 28.8px;
  font-weight: 600;
  color: $gray-3;
  text-align: center;
  max-width: 350px;

  @include maxWidth($bp-mTablet) {
    font-size: 17px;
    line-height: 22px;
  }

  @include maxWidth($bp-mobile) {
    font-size: 15px;
    line-height: 21.6px;
  }
}

.button {
  width: 340px !important;
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 800 !important;
  height: 63px !important;

  span {
    font-family: $font-main;
  }

  @include maxWidth($bp-mobile) {
    font-size: 15px !important;
    line-height: 21.6px !important;
    width: 295px !important;
    min-height: 50px !important;
    height: 50px !important;
  }
}
