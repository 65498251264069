@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

$transition-duration: 0.3s;
$transition-opacity: 70%;
$link-transition: opacity $transition-duration;

.container {
  background-color: #eff4ff;
  height: 100%;
  position: relative;
}

.page {
  max-width: 1440px;
  margin: 0 auto;
}

// Header

.header {
  min-height: 420px;
  padding: 35px 40px 100px;
  background-color: $blue;
  background-image: url(/images/credit-cards-v2.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 12px 12px;

  @include maxWidth($bp-tablet) {
    background-size: 50% 50%;
  }

  @include maxWidth($bp-mTablet) {
    padding: 25px 25px 100px;
    background-position: 100% 115%;
    min-height: 350px;
  }

  @include maxWidth($bp-mobile) {
    padding: 12px 16px 100px;
    min-height: 266px;
  }
}

.header__top {
  display: flex;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: $link-transition;

  @include maxWidth($bp-mobile) {
    column-gap: 4px;
  }
}

.header__logo:hover {
  cursor: pointer;
  opacity: $transition-opacity;
}

.logoImage {
  width: 95px;

  @include maxWidth($bp-mobile) {
    width: 50px;
  }
}

.logoTitle {
  color: $white;

  @include maxWidth($bp-mobile) {
    font-size: 18px !important;
  }
}

.header__phone {
  margin-top: 5px;
  width: 64px;
  height: 64px;
  transition: $link-transition;

  @include maxWidth($bp-mobile) {
    width: 32px;
    height: 32px;
  }
}

.header__phone:hover {
  cursor: pointer;
  opacity: $transition-opacity;
}

.header__title {
  color: $white;
  font-size: 48px !important;
  line-height: 57.6px !important;
  font-family: $font-secondary !important;

  span {
    display: block;
  }

  @include maxWidth($bp-mTablet) {
    font-size: 38px !important;
    line-height: 47px !important;
  }

  @include maxWidth($bp-mobile) {
    font-size: 28px !important;
    line-height: 40.32px !important;
  }
}

// Main
.main {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding: 40px 16px;

  @include maxWidth($bp-mTablet) {
    flex-direction: column-reverse;
    gap: 32px;
    padding: 32px 16px;
  }
}

.section {
  font-family: $font-secondary;
  width: 50%;
  background-color: $white;
  border-radius: 12px;
  padding: 24px;

  @include maxWidth($bp-mTablet) {
    width: 100%;
    padding: 24px 16px;
  }
}

.sectionTitle {
  font-family: $font-secondary;
  font-size: 28px !important;
  line-height: 40.32px !important;

  @include maxWidth($bp-mobile) {
    font-size: 24px !important;
    line-height: 34.56px !important;
  }
}

// Common Info

.infoListItem {
  border-bottom: 1px solid #ccd0da;
  padding: 25px 0 8px;
  display: grid;
  grid-template-columns: 215px auto;
  gap: 30px;
  align-items: center;

  @include maxWidth($bp-tablet) {
    grid-template-columns: auto;
    gap: 8px;
    padding: 16px 0 8px;
  }
}

.infoListItem:last-child {
  border: none;
}

.infoListItemTitle {
  font-family: $font-secondary !important;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 24.48px !important;
  color: var(--color-gray3);

  @include maxWidth($bp-mobile) {
    font-size: 16px !important;
    line-height: 21.76px !important;
  }
}

.infoListItemText {
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;

  @include maxWidth($bp-mobile) {
    font-size: 16px !important;
    line-height: 23.04px !important;
  }
}

.link {
  color: $blue;
  transition: $link-transition;
  display: inline-block;
}

.link:hover {
  opacity: $transition-opacity;
}

// Form
.form {
  padding-top: 6px;
}

.titleWithButtonRow {
  display: flex;
  justify-content: space-between;

  @include maxWidth($bp-tablet) {
    flex-direction: column-reverse;
  }
}

.formDescription {
  margin-top: 12px;
  font-size: 16px;
  line-height: 23.04px;
  color: var(--color-gray3);

  @include maxWidth($bp-mobile) {
    font-size: 12px;
    line-height: 16.32px;
  }
}

.shortInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

  @include maxWidth($bp-tablet) {
    grid-template-columns: 1fr;
  }
}

.checkBoxWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.buttonsWrapper {
  margin-top: 32px;
  display: flex;
  gap: 24px;

  @include maxWidth($bp-mobile) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.cancelButton {
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  color: $blue;
  background-color: $gray;

  span {
    font-family: $font-main;
  }

  @include maxWidth($bp-mobile) {
    font-size: 15px;
    line-height: 21.6px;
  }
}

.saveButton {
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;

  span {
    font-family: $font-main;
  }

  @include maxWidth($bp-mobile) {
    font-size: 15px;
    line-height: 21.6px;
  }
}

.startEditingButton {
  font-size: 12px;
  line-height: 13.92px;
  font-weight: 700;
  color: $blue;
  transition: $link-transition;
  display: flex;
  align-items: center;
  gap: 4px;

  @include maxWidth($bp-tablet) {
    margin-left: auto;
  }
}

.startEditingButton:hover {
  opacity: $transition-opacity;
}

.startEditingButton:disabled {
  cursor: default;
}

.startEditingButton:disabled:hover {
  opacity: 1;
}

.inputWrapper {
  margin-top: 10px;
}

.input {
  font-family: $font-secondary;
  margin-top: 4px;
  background-color: #eff4ff;
  font-size: 18px;
  line-height: 25.92px;
  font-weight: 600;
  height: 60px;

  @include maxWidth($bp-mobile) {
    height: 48px;
    font-size: 16px;
    line-height: 23.04px;
  }
}

.label {
  color: $gray-9;
}

.inputError {
  position: static;
  text-align: left;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}

.radioList {
  display: inline-flex;
  gap: 11px;
  flex-direction: column;
  margin-top: 17px;
  margin-bottom: 20px;
  width: max(250px, 50%);

  @include maxWidth($bp-mobile) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.radioList:nth-of-type(2n) {
  margin-top: 0;

  @include maxWidth($bp-mobile) {
    margin-top: 10px;
  }
}

.radioListItems {
  display: flex;
  gap: 10px;
}

.radio {
  color: $gray-9;
  font-size: 18px;
  font-weight: 600;

  @include maxWidth($bp-mobile) {
    font-size: 16px;
    line-height: 23.04px;
  }
}

// Select
.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--blue-light);
  background: var(--blue-light);
  color: $gray-6;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.92px;
  width: 100%;
  height: 60px;
  padding: 12px 15px;
  transition: ease 0.2s;
  cursor: pointer;
  svg {
    transition: ease 0.2s;
  }
  &_isOpen {
    border: 1px solid var(--clr-bg-primary);
    svg {
      transform: rotate(180deg);
    }
  }
  &_isDisabled {
    cursor: default;
    background: #f5f8fd;
  }

  @include maxWidth($bp-mobile) {
    font-size: 16px;
    line-height: 21.6px;
    height: 48px;
  }
}

.option {
  position: absolute;
  top: 8px;
  z-index: 20;
  background-color: var(--clr-white);
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: ease 0.2s;
  z-index: 101;
  &_isOpen {
    max-height: 200px;
  }

  &__inner {
    display: flex;
    padding: 12px 15px;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--blue-light);
    background-color: var(--blue-light);
    width: 100%;
  }

  &__wrapper {
    position: relative;
  }

  &__item {
    color: $gray-9;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.92px;
    cursor: pointer;

    @include maxWidth($bp-mobile) {
      font-size: 16px;
      line-height: 23.04px;
    }
  }

  &__activeItem {
    color: $blue;
  }
}

.disabled {
  cursor: default;
}

// Error scrren
.headerEmpty {
  padding: 40px;

  @include maxWidth($bp-mobile) {
    padding: 16px;
  }
}

.blueLogoTitle {
  color: $blue;
}

.mainErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.errorImage {
  width: 364px;

  @include maxWidth($bp-mobile) {
    width: 271px;
    margin-top: 20px;
  }
}

.errorMessage {
  color: $gray-9;
  font-size: 20px;
  line-height: 28px;
  max-width: 860px;
  text-align: center;
  font-family: $font-secondary;

  @include maxWidth($bp-mobile) {
    font-size: 15px;
    line-height: 21px;
    margin-top: 24px;
  }
}

.onMainButton {
  max-width: 340px;
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  margin-top: 60px;

  span {
    font-family: $font-main;
  }

  @include maxWidth($bp-mobile) {
    font-size: 15px;
    line-height: 21.6px;
    min-height: 50px !important;
    height: 50px !important;
    margin-top: 32px;
  }
}
