@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.header {
  flex-shrink: 0;
  // height: 78px;
}

.container {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  height: 100%;
  padding: 28px 0 16px;

  &--discount {
    @include maxWidth($bp-tablet) {
      width: calc(100% - var(--container-space, #{$space-tablet}) * 3);
    }

    @include maxWidth($bp-mTablet) {
      width: calc(100% - var(--container-space, #{$space-mTablet}) * 3);
    }

    @include maxWidth($bp-lMobile) {
      width: calc(100% - var(--container-space, #{$space-lMobile}) * 3);
    }
  }
}

.logo {
  svg,
  img {
    width: auto;
    height: 44px;
  }

  @include noTouchHover() {
    opacity: 0.9;
  }

  &__Active {
    cursor: default;
    pointer-events: none;
  }
}

.back {
  width: fit-content;
  height: 19px;
  margin-top: 8px;
  padding: 8px 0;
  box-sizing: content-box;
  display: flex;
  font-family: $font-main;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: $blue;

  @include noTouchHover() {
    opacity: 0.9;
  }
}
