@use '@/assets/styles/vars/scss-variables' as *;

.list {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  max-height: 148px;
  overflow-y: auto;
  display: flex;
  padding: 12px 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid $gray;
  background: $white;

  & li {
    width: 100%;
    color: $gray-3;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
