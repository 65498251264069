@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.container {
  min-width: $min-width;
  width: calc(100% - var(--container-space, #{$space-desktop}) * 2);
  max-width: var(--container-max-width, #{$max-width});
  margin-left: auto;
  margin-right: auto;

  @include maxWidth($bp-tablet) {
    width: calc(100% - var(--container-space, #{$space-tablet}) * 2);
  }

  @include maxWidth($bp-mTablet) {
    width: calc(100% - var(--container-space, #{$space-mTablet}) * 2);
  }

  @include maxWidth($bp-lMobile) {
    width: calc(100% - var(--container-space, #{$space-lMobile}) * 2);
  }
}