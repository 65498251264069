@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.container {
  display: flex;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: $max-width;
}

.preview {
  position: relative;
  max-height: 400px;
  background: var(--clr-bg-decor);
  border-radius: 0 0 24px 24px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-top: 50%;
  }

  &__wrapper {
    position: relative;
  }

  &__image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__logo {
    position: absolute;
    z-index: 10;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__logoCircle {
    position: absolute;
    z-index: 10;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #fff;
  }

  &__customisingLogo {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
  }
}

.title {
  margin-top: 28px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: center;
  color: $gray-2;
}

.cards {
  margin-top: 16px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;
  padding: 16px;
  margin-bottom: 54px;
  background: $blue-2;
  border-radius: 24px;
}

.footer {
  margin-top: -14px;
  padding-bottom: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;

  a {
    line-height: 22px;
    color: var(--clr-text-normal);

    &:hover {
      color: var(--clr-text-main);
      text-decoration: underline;
    }
  }

  img {
    margin: 0 auto;
  }
}


.mobileApps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}

.mobileApp {
  width: 180px;
  height: auto;
  object-fit: contain;
}