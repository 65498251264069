.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--blue-light);
  color: var(--color-gray2);
  font-size: 15px;
  font-weight: 400;
  line-height: 144%;
  width: 100%;
  padding: 12px 15px;
  transition: ease 0.2s;
  cursor: pointer;
  svg {
    transition: ease 0.2s;
  }
  &_isChooseValue {
    color: #07090f;
  }
  &_isOpen {
    border: 1px solid var(--clr-bg-primary);
    background: var(--blue-light);
    svg {
      transform: rotate(180deg);
    }
  }
}

.option {
  position: absolute;
  top: 8px;
  z-index: 20;
  background-color: var(--clr-white);
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: ease 0.2s;
  &_isOpen {
    max-height: 200px;
  }

  &__inner {
    display: flex;
    padding: 12px 15px;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--blue-light);
    background: var(--clr-white);
    width: 100%;
  }

  &__wrapper {
    position: relative;
  }

  &__item {
    color: var(--navy);
    font-size: 15px;
    font-weight: 400;
    line-height: 144%;
    cursor: pointer;
  }
}
