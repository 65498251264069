:root {
  // fonts
  --font-main: 'Mulish', sans-serif;
  --font-secondary: 'Open Sans', sans-serif;

  // colors
  --clr-text-main: #23262f;
  --clr-text-main-rgb: 35, 38, 47;
  --clr-text-secondary: #777e90;
  --clr-text-normal: #878da0;
  --clr-bg-secondary: #f7f7f9;
  --clr-bg-decor: #f2f9fe;
  --clr-bg-primary: #3879f0;
  --clr-bg-accent: #192342;
  --clr-bg-border: #e8ebf1;
  --clr-bg-success: #567df4;
  --clr-bg-success-rgb: 86, 125, 244;
  --clr-bg-error: #fc5151;
  --clr-bg-error-rgb: #fc5151;

  --clr-primary: #3879f0;
  --clr-primary-rgb: 56, 121, 240;
  --clr-primary-active: #2c68d7;

  --clr-control-bg-default: #f7f7f9;
  --clr-control-bg-border-default: #f7f7f9;
  --clr-control-bg-border-default-hover: var(--clr-bg-border);
  --clr-control-text-default: var(--clr-text-main);
  --clr-control-bg-primary: var(--clr-bg-primary);
  --clr-control-bg-primary-active: #2c68d7;
  --clr-control-text-primary: #ffffff;
  --clr-control-bg-system: #ffffff;
  --clr-control-bg-system-active: #f7f7f9;
  --clr-control-text-system: var(--clr-text-main);
  --clr-control-bg-accent: var(--clr-bg-accent);
  --clr-control-bg-accent-active: var(--clr-bg-accent);
  --clr-control-text-accent: #ffffff;
  --color-control-bg-disable: #ccd0da;
  --color-control-text-disable: #ffffff;

  --color-white3: #f1f3f8;
  --dark-gray-1: #8c909b;
  --color-gray2: #7c808b;
  --color-gray3: #787b84;
  --color-gray4: #585a61;
  --light-gray-3: #b4b8c3;
  --color-blue: #12327b;
  --blue-light: #ecf2ff;

  --navy: #222b45;

  --clr-error: #fc5151;

  --clr-white: #ffffff;
  --clr-black: #000000;

  // radius
  --radius-s: 6px;
  --radius-m: 10px;
  --radius-xl: 25px;
}
