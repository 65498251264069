@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.choice {
  display: block;
  cursor: pointer;
}

.radio {
  .title {
    &::before,
    &::after {
      border-radius: var(--choice-frame-radius, 50%);
    }

    &::after {
      top: var(--choice-circle-top, 16px);
      left: var(--choice-circle-left, 8px);
      background-image: none;
      background-color: #fff;
      width: 12px;
      height: 12px;
    }
  }
}

.title {
  position: relative;
  display: block;
  padding-top: var(--choice-pt, 9px);
  padding-bottom: var(--choice-pb, 10px);
  padding-right: var(--choice-pr, 0);
  padding-left: var(--choice-pl, 38px);
  font-family: var(--choice-ff, inherit);
  font-weight: var(--choice-fw, inherit);
  font-size: var(--choice-fz, 20px);
  line-height: 1.3;
  color: var(--choice-color, var(--clr-n15));
  user-select: none;

  &::before,
  &::after {
    content: var(--choice-frame-content, '');
    position: absolute;
    top: var(--choice-frame-top, 8px);
    right: var(--choice-frame-right, auto);
    bottom: var(--choice-frame-bottom, auto);
    left: var(--choice-frame-left, 0);
    display: block;
    width: var(--choice-frame-size, 28px);
    height: var(--choice-frame-size, 28px);
  }

  &::before {
    background-color: var(--choice-frame-bg-color, var(--clr-n3));
    border: 1px solid var(--clr-n3);
    border-radius: 6px;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }

  &::after {
    opacity: 0;
    transform: scale(0.4);
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33602 10.8482C2.9455 10.4577 2.9455 9.82453 3.33602 9.43401L11.6185 1.15157C12.009 0.761049 12.6421 0.76105 13.0327 1.15157C13.4232 1.5421 13.4232 2.17526 13.0327 2.56579L4.75023 10.8482C4.35971 11.2387 3.72655 11.2387 3.33602 10.8482Z' fill='%23ffffff'/%3E%3Cpath d='M4.74911 10.8486C4.35858 11.2391 3.72542 11.2391 3.33489 10.8486L0.966873 8.48054C0.576349 8.09001 0.57635 7.45685 0.966874 7.06633C1.3574 6.6758 1.99056 6.6758 2.38109 7.06633L4.74911 9.43435C5.13963 9.82487 5.13963 10.458 4.74911 10.8486Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 52% auto;
    transform-origin: center;
    transition: opacity 0s 0.2s, transform 0.2s ease-out;
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;

  &:checked + .title {
    &::before {
      background-color: var(--clr-decor-main);
      border-color: var(--clr-decor-main);
    }

    &::after {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0s, transform 0.2s ease-out;
    }
  }

  &:focus + .title {
    color: var(--clr-text-accent);
  }

  &:focus:not(:checked) + .title {
    &::before {
      border-color: var(--clr-n7);
    }
  }

  &:disabled + .title {
    cursor: default;
    opacity: 0.5;
  }

  &:disabled:checked + .title {
    &::before {
      opacity: var(--choice-frame-disabled-opacity, 0.3);
    }
  }

  &:not(:checked):not(:disabled) + .title {
    &:hover {
      color: var(--clr-text-accent);

      &::before {
        border-color: var(--clr-n7);
      }
    }
  }
}

.radio_img {
  position: relative;

  .title {
    text-align: center;
    padding: 8px;
    border: 1px solid $border;
    border-radius: 24px;
    transition: border-color 0.16s;
    overflow: hidden;

    &::before,
    &::after {
      content: none;
    }
  }

  .input:not(:checked):not(:disabled) + .title {
    @include noTouchHover() {
      border-color: $blue;
    }
  }

  .input:checked + .title {
    border-color: var(--clr-bg-primary);
  }
}

.img {
  position: relative;
  display: block;
  border-radius: 16px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  &::after {
    content: '';
    display: block;
    padding-top: 56.26%;
  }
}

.alt {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 28px;
}

.radioInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: default;
  }
  &__title {
    color: var(--color-gray2);
    font-size: 15px;
    font-weight: 400;
    line-height: 144%;
    &_isCheked {
      color: $gray-6;
    }
  }
}

.radioInput + label {
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 50%;
  background: var(--color-white3);
  cursor: pointer;
  border: 1px solid $blue;
}

.radioInput:checked + label {
  border: 1px solid $blue;
}

.radioInput:disabled + label {
  cursor: default;
  opacity: 0.5;
}

.radioInput:checked + label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: $blue;
  border-radius: 100%;
}

.checkBoxInput {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__title {
    color: var(--color-gray4);
    font-size: 12px;
    font-weight: 600;
    line-height: 17.28px;
    a {
      color: $blue;
      font-size: 12px;
      font-weight: 600;
      line-height: 17.28px;
      text-decoration-line: underline;
    }
  }
}

.checkBoxInput[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  user-select: none;
}

.checkBoxInput[type='checkbox']:checked + .checkbox {
  position: relative;
  background: $blue;
  border: none;
  &:after {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjY2NjYgMy41TDUuMjQ5OTIgOS45MTY2N0wyLjMzMzI1IDciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    top: 0;
    z-index: 100;
    position: absolute;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  background: var(--clr-white);
  border: 1px solid var(--color-gray3);
  border-radius: 2px;
}
