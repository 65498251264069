@use '@/assets/styles/vars/scss-variables' as *;

.input {
  display: block;
  margin-top: 16px;

  &__error {
    position: relative;
  }
}
