$font-main: var(--font-main);
$font-secondary: var(--font-secondary);

// breakpoints
$bp-xsMobile: 325;
$bp-sMobile: 375;
$bp-mobile: 500;
$bp-lMobile: 650;
$bp-mTablet: 768;
$bp-tablet: 1024;
$bp-sDesktop: 1280;
$bp-xmDesktop: 1366;
$bp-mDesktop: 1440;
$bp-desktop: 1920;

// colors
$white: #FFF;
$blue: #3879F0;
$blue-2: #F8FAFF;

$gray: #ECF2FF;
$gray-2: #222B45;
$gray-3: #8C909B;
$gray-4: #585A61;
$gray-5: #3D414B;
$gray-6: #07090F;
$gray-7: #878DA0;
$gray-8: #999DA9;
$gray-9: #192136;
$gray-10: #CCD0DA;
$border: #DCE0E7;
$border-2: #E8EBF1;
$border-3: #F1F3F8;

$red: #F45353;

$max-width: 1328px;
$min-width: 280px;
$space-desktop: 56px;
$space-tablet: 36px;
$space-mTablet: 28px;
$space-lMobile: 16px;