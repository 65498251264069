@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 8px;
  width: 100%;
  padding: 12px 32px;
  background: var(--color-white3);
  color: var(--light-gray-3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-weight: 600;
  line-height: 144%;
  display: flex;
  align-items: center;
  gap: 24px;
  span {
    color: var(--dark-gray-1);
  }
  @include maxWidth($bp-lMobile) {
    flex-direction: column;
    gap: 4px;
    padding: 8px 32px;
  }
}
