@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.title {
  display: block;
  max-width: var(--title-max-width, 100%);
  margin-bottom: var(--title-mb, 0);
  font-family: var(--font-main);
  font-weight: var(--title-fw, bold);
  text-align: var(--title-align, left);
  color: var(--title-color, var(--clr-text-main));

  font-family: $font-main;
  font-weight: 700;
  font-size: 32px;
  line-height: 116%;
  color: $gray-2;

  &.center {
    text-align: var(--title-align, center);
  }

  &.size-32 {
    font-size: var(--title-fz, 32px);
    line-height: var(--title-lh, 1.25);
  }

  &.size-24 {
    font-size: var(--title-fz, 24px);
    line-height: var(--title-lh, 1.25);
  }

  &.level-h4 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 144%;
    color: $gray-4;
  }

  &.level-h1 {
    font-family: $font-main;
    font-weight: 700;
    font-size: 32px;
    line-height: 116%;
  }
}