@use '@/assets/styles/vars/scss-variables'as *;
@use '@/assets/styles/mixins'as *;

.text {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;

  mark {
    background: 0 0;
    color: var(--text-mark-color, var(--clr-primary));
  }

  &.size-18 {
    font-size: var(--text-fz, 18px);
    line-height: var(--text-lh, 1.8);
  }

  &.level-1 {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 15px;
    line-height: 144%;
  }

  &.level-2 {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 13px;
    line-height: 136%;
  }

  &.bold {
    font-weight: 700;
  }
}